@import url('https://fonts.googleapis.com/css2?family=Playfair+Display+SC:ital@0;1&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300&family=Raleway:ital,wght@0,200;0,300;0,400;0,600;1,100;1,300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100;0,200;0,300;0,400;1,200;1,300&family=Playfair+Display+SC:ital@0;1&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300&family=Raleway:ital,wght@0,200;0,300;0,400;0,500;0,600;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
*{
margin: 0;
padding: 0;
}
body
{
overflow-x: hidden;
}
a
{
text-decoration: none !important;
}
.container
{
padding-left: 0px !important;
padding-right: 0px !important;
}
.logo
{
max-width: 13em!important;
}
.short-desc
{
font-family: 'Raleway', sans-serif;
}
.fs-13
{
    font-size: 13px !important;
}
p
{
font-family: 'Noto Sans', sans-serif;
color: #000;
}
.lst-0
{
    list-style-type: none;
}
.navbar-light
{
top: 0;
left: 0;
width: 100%;
z-index: 999;
font-family: 'Poppins', sans-serif;
}
.header-shrink
{  
transition-duration: 500ms ease-out;
transition-property: all; 
background-color: #ffffff !important;
box-shadow: 0 2px 10px 0px rgba(0, 0, 0, 0.15);
}
.custom-shadow{       
border-bottom: 2px solid rgba(255, 255, 255, 0.5);      
background: url(../img/banner/banner-bg.jpg);
background-position: center;
background-repeat: no-repeat;
background-size: cover;
}
.navbar{
padding: 0px 1rem !important;
}
/* menu css  */
li.menu_has_children:after {
position: absolute;
content: "\f078";
top: 3px;
font-size: 13px;
right: -2px;
color: #333745;
font-family: 'FontAwesome';
z-index: -10;
-webkit-transition: all 0.3s;
-o-transition: all 0.3s;
transition: all 0.3s;
z-index: 2;
}
li.menu_has_children:hover:after {
transform: rotate(-180deg);
-webkit-transform: rotate(-180deg);
-moz-transform: rotate(-180deg);
-ms-transform: rotate(-180deg);
-o-transform: rotate(-180deg);
}
.main-menu li {
position: relative;
}
.main-menu li a {
padding: 0px 14px;
display: inline-block;
font-size: 15px;
font-weight: 500;
color: #000;
transition: all 0.3s;
-webkit-transition: all 0.3s;
-moz-transition: all 0.3s;
-ms-transition: all 0.3s;
-o-transition: all 0.3s;
}
.main-menu > li:last-child a {
padding-right: 0;
}
.sub-menu {
position: absolute;
left: -20px;
top: 35px !important;
background-color: #ffffff;
-webkit-box-shadow: 0px 18px 54px -8px rgba(0,0,0,0.15);
box-shadow: 0px 18px 54px -8px rgba(0,0,0,0.15);
width: 190px;
z-index: 999;
transition: all 0.3s;
-webkit-transition: all 0.3s;
-moz-transition: all 0.3s;
-ms-transition: all 0.3s;
-o-transition: all 0.3s;
opacity: 0;
visibility: hidden;
border-top: 2px solid var(--main-color);
border-radius: 5px;
}
.main-menu li:hover > .sub-menu {
top: 100%;
opacity: 1;
visibility: visible;
}
.sub-menu li + li {
border-top: 1px solid rgba(0, 0, 0, 0.15);
}
.sub-menu li a {
padding: 10px 18px;
display: block;
color: #F06D70;
font-size: 15px;
text-transform: capitalize;
}
.sub-menu li a:hover {
background-color: rgba(40, 125, 253,0.08);
}
.transparent--header .sub-menu li a {
color: #ffffff;
}
.sub-menu li.menu_has_children:after {
position: absolute;
content: "\f105";
top: 6px;
right: 30px;
}
.sub-menu li:hover .sub-menu {
opacity: 1;
visibility: visible;
left: 100%;
top: 0;
}
.main-menu ul{
padding-left: 0 !important;
}
.home{
background: url(../img/banner/banner-bg.jpg) no-repeat;
background-size: cover;
background-position: center;
height: 100vh;
}
.home .row .books-slider a img{
height: 22rem;
margin-bottom: -22px;
}
.home .row .books-slider a:hover img{
transform: scale(.9);
}
.home .carousel-indicators,.carousel-control-prev,.carousel-control-next
{
display: none!important;
}
.home .row .content h2{
color:#0c2e8a; 
font-weight: 700;
font-family: 'Poppins', sans-serif;
}
h1
{
padding: 20px 0px;
text-align: center;
color: #0c2e8a;
font-size: 31px !important;
font-weight: 600!important;
position: relative;
font-family: 'Poppins', sans-serif;
}
.home .row .content p{
color:#000;   
line-height: 2;
padding:1rem 0;   
}
.btn-custom
{
border: 0 !important;  
color: #FFFFFF !important;
cursor: pointer !important; 
font-weight: 500 !important;
outline: transparent !important;
padding: 10px 18px !important;
text-align: center !important;
transition: box-shadow .2s ease-in-out !important;   
background-color: #f44556 !important;
font-family: 'Poppins', sans-serif!important; 
}
.btn-custom:not([disabled]):hover {
box-shadow: 0 0 0.25rem rgb(0 0 0 / 50%), -0.125rem -0.125rem 1rem rgb(239 71 101 / 50%), 0.125rem 0.125rem 1rem rgb(255 154 90 / 50%);
}
.has-search .form-control {
padding-left: 2.375rem;
border-radius: 10px;
border: none;
box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
.has-search .form-control-feedback {
position: absolute;
z-index: 2;
display: block;
width: 2.375rem;
height: 2.375rem;
line-height: 2.375rem;
font-size: 20px;
text-align: center;
pointer-events: none;
color: #f44556;
}
label
{
font-family: 'Raleway', sans-serif; 
font-weight: 700;
color: #0c2e8a;
margin-bottom: 8px;
}
.modal-title,.form-control
{
font-family: 'Poppins', sans-serif;  
}
.fa-times
{
color: #f44556;
font-size: 26px;
}
/* klf */
.klf-caption h3{
font-size: 28px;
font-weight: 600;
font-family: 'Poppins', sans-serif;  
color: #0c2e8a;
}
.klf-caption h2{
font-family: 'Raleway', sans-serif;  
color: #dc3545; 
font-size: 29px;
}
.klf-link
{
box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
border-radius: 5px;
padding: 10px ;
transition: ease-in 0.5s;
} 
.klf-link img
{
max-width: 44px;
}
.klf-link p
{
color: #000;
padding-left: 15px;
padding-top: 9px;
font-family: 'Poppins', sans-serif;  
}
.klf-link:hover
{
transition: ease-in 0.5s;
background: #f4f9ff;
box-shadow: 0px 7px 5px rgb(0 0 0 / 11%);
}
.text-poppins
{
font-family: 'Poppins', sans-serif !important;  
}
.text-rale
{
font-family: 'Raleway', sans-serif;  
}
h1::before
{
content: "";
position: absolute;
bottom: 0;
left: 50%;
transform: translateX(-50%);
width: 230px;
height: 1px;
background-color: #f70037;
}
h1::after
{
content: "";
position: absolute;
bottom: -1px;
left: 50%;
transform: translateX(-50%);
width: 75px;
height: 3px;
background-color: #f70037;
}
.bg-light-blue
{
background-color: #f4f9ff;
}
.img-box1 {
position: relative;
z-index: 2;
margin-right: 18px;
margin-left: 50px
}
.img-box1 .img1 img {
width: 100%;
max-width: 650px
}
.img-box1 .shape1 {
position: absolute;
bottom: 0px;
right: 80px;
z-index: -1
}
.img-box1 .year-counter {
position: absolute;
bottom: 30px;
right: 0
}
.year-counter {
height: 162px;
width: 162px;
background-color: #f44556;
border: 12px solid #fff;
box-shadow: 0px 10px 30px rgba(8, 14, 28, 0.1);
border-radius: 50%;
text-align: center;
padding: 25px 0
}
.year-counter_number {
color: #fff;
font-size: 43px;
margin-bottom: 0px;
line-height: 1.1
}
.year-counter_text {
color: #fff;
margin-bottom: 0;
font-weight: 400
}
/* service */
.ba::after{
left: 0;
bottom: 0;
transition-delay: 0.6s;
}
.ba span::after{
transition-delay: 0.4s;
right: 0;
bottom: 0
}
.ba::before{
right: 0;
top: 0;
transition-delay: 0.2s;
}
.ba span::before{
transition-delay: 0s;
left: 0;
top: 0;
}
.ba:hover::after{
transition-delay: 0s;
}
.ba:hover span::after{
transition-delay: 0.2s;
}
.ba:hover::before{
transition-delay: 0.4s;
}
.ba:hover span::before{
transition-delay: 0.6s;
}
.ba::before, .ba::after{
content:"";
width: 0;
height: 2px;
position: absolute;
transition: all 0.2s linear;
background: linear-gradient( to right,#ea1d24 0%,#e71828 12%,#e31a2c 16%,#e31929 18%,#dc1830 26%,#da1533 33%,#d91732 35%,#d71436 38%,#d51335 45%,#d1123b 49%,#d1123b 50%,#d01037 51%,#d0113c 54%,#ce103c 56%,#cb113a 57%,#cb0f3f 62%,#c71040 62%,#c50f40 70%,#c61043 70%,#c30c42 73%,#c30e41 74%,#c20c45 78%,#bf0c43 79%,#b8094a 91%,#b6094b 100% );
}
.ba span::before, .ba span::after{
content:"";
width:2px;
height:0;
position: absolute;
transition: all 0.2s linear;
background: linear-gradient( to right,#ea1d24 0%,#e71828 12%,#e31a2c 16%,#e31929 18%,#dc1830 26%,#da1533 33%,#d91732 35%,#d71436 38%,#d51335 45%,#d1123b 49%,#d1123b 50%,#d01037 51%,#d0113c 54%,#ce103c 56%,#cb113a 57%,#cb0f3f 62%,#c71040 62%,#c50f40 70%,#c61043 70%,#c30c42 73%,#c30e41 74%,#c20c45 78%,#bf0c43 79%,#b8094a 91%,#b6094b 100% );
}
.ba:hover::before, .ba:hover::after{
width: 100%;
}
.ba:hover span::before, .ba:hover span::after{
height: 100%;
}
.ba{    
position: relative;    
cursor: pointer;
}
/* service */
.service-we-offer .col-md-4
{
cursor: pointer;
}
.card {
/* background: rgba(0, 0, 0, 0.2); */
box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; 
border-radius: 8px;
overflow: hidden;
}
.card .card-img {
perspective: 1000px;
}
.card .card-img .flip-card-inner {
position: relative;   
text-align: center;
transition: transform 0.8s;
transform-style: preserve-3d;
}
.col-md-4:hover .flip-card-inner {
transform: rotateY(180deg);    
}
.card .card-img .flip-card-front,.card .card-img .flip-card-back {
position: absolute;
width: 100%;
height: 100%;
-webkit-backface-visibility: hidden;   
backface-visibility: hidden;   
}
.card .card-img .flip-card-front {
background-color: #bbb;
color: black;   
}
.card .card-img .flip-card-back {  
transform: rotateY(180deg);
text-align: justify;
}
.card .card-img img {
width: 100%;
height: 13rem;
object-fit: cover;
object-position: top;
}
.card .card-body {
margin-top: 14rem;
border-top: 1px solid;
padding: 10px 5px;
text-align: center;
}
.card .card-body h6 {
margin: 0;
color: #000;
font-family: 'Poppins', sans-serif;  
}
h6 img
{
max-width: 42px !important;
padding-right: 8px;
}
.flip-card-back p
{
color: #444;
font-family: 'Raleway', sans-serif;
font-size: 15px;
}
/* matrix */
.main-timeline{ font-family: 'Poppins', sans-serif; }
.main-timeline:after{
content: '';
display: block;
clear: both;
}
.main-timeline:before{
content: '';
background-color: #0c2e8a;
height: 43em;
width: 4px;
transform: translateX(-50%);
position: absolute;
left: 50%;
}
.main-timeline .timeline{
width: 50%;
padding: 3px 0;
margin: 0 2px 0 0;
float: left;
}
.main-timeline .timeline-content{
color: #777;
padding: 0 130px 0 0;
display: block;
position: relative;
}
.main-timeline .timeline-content:hover{ text-decoration: none; }
.main-timeline .timeline-content:before{
content: '';
background: #fff;
width: 20px;
height: 20px;

border-radius: 50%;
transform: translateY(-50%);
position: absolute;
right: -10px;
top: 50%;
}
.tc-1:before
{
    border: 3px solid #ec496e;
}
.tc-2:before
{
    border: 3px solid #f9850f;
}
.tc-3:before
{
    border: 3px solid #8fb800;
}
.tc-4:before
{
    border: 3px solid #2fcea5;
}

.main-timeline .timeline-icon{
color: #fff;
background-color: #ec496e;
font-size: 35px;
font-weight: 600;
text-align: center;
line-height: 80px;
height: 100px;
width: 100px;
border-radius: 50%;
border: 10px solid #fff;
box-shadow: 0 0 10px rgba(0,0,0,0.25);
transform: translateY(-50%);
position: absolute;
top: 50%;
right: 11px;
z-index: 1;
}
.main-timeline .title{
color: #ec496e;
font-size: 22px;
font-weight: 600;
text-transform: capitalize;
letter-spacing: 0.5px;
margin: 0 0 8px;
}
.main-timeline .description{
font-size: 14px;
letter-spacing: 1px;
line-height: 22px;
margin: 0;
}
.main-timeline .timeline:nth-child(even){
margin: 0 0 0 2px;
float: right;
}
.main-timeline .timeline:nth-child(even) .timeline-content{ padding: 0 0 0 130px; }
.main-timeline .timeline:nth-child(even) .timeline-content:before{
right: auto;
left: -10px;
}
.main-timeline .timeline:nth-child(even) .timeline-icon{
right: auto;
left: 11px;
}
.main-timeline .timeline:nth-child(4n+2) .timeline-icon{ background-color: #f9850f; }
.main-timeline .timeline:nth-child(4n+2) .title{ color: #f9850f; }
.main-timeline .timeline:nth-child(4n+3) .timeline-icon{ background-color: #8fb800; }
.main-timeline .timeline:nth-child(4n+3) .title{ color: #8fb800; }
.main-timeline .timeline:nth-child(4n+4) .timeline-icon{ background-color: #2fcea5; }
.main-timeline .timeline:nth-child(4n+4) .title{ color: #2fcea5; }
@media screen and (max-width:767px){
.main-timeline:before{
transform: translateX(0);
left: 23px;
}
.main-timeline .timeline,
.main-timeline .timeline:nth-child(even){
width: 100%;
margin: 0 0 20px;
}
.main-timeline .timeline-content,
.main-timeline .timeline:nth-child(even) .timeline-content{
padding: 0 0 0 150px;
}
.main-timeline .timeline-content:before,
.main-timeline .timeline:nth-child(even) .timeline-content:before{
left: 0;
}
.main-timeline .timeline-icon,
.main-timeline .timeline:nth-child(even) .timeline-icon{
left: 20px;
right: auto;
}
}
@media screen and (max-width:479px){
.main-timeline .timeline-content,
.main-timeline .timeline:nth-child(even) .timeline-content{
padding: 120px 0 0 25px;
}
.main-timeline .timeline-content:before,
.main-timeline .timeline:nth-child(even) .timeline-content:before{
transform: translateY(0);
top: 43px;
}
.main-timeline .timeline-icon,
.main-timeline .timeline:nth-child(even) .timeline-icon{
transform: translateY(0);
top: 0;
}
}
.timeline:hover .img {
-webkit-transform-origin: top center;
transform-origin: top center;
-webkit-animation-name: swing;
animation-name: swing;
-webkit-animation-duration: 1s;
animation-duration: 1s;
-webkit-animation-duration: var(--animate-duration);
animation-duration: var(--animate-duration);
-webkit-animation-fill-mode: both;
animation-fill-mode: both;
}
.timeline-icon img
{
max-width: 62px;
}
/* why us*/
.why-img1
{
position: relative;   
right: 63px;
z-index: 9;
}
.why-img2
{
position: relative;
top: -10em;
}
.year-counter.why
{
border-radius: 20px;
height:81px;
background-color: #fff;
width: 164px;
padding: 0px;
}
.why .year-counter_text,.why .year-counter_number {
color:#f44556 ;
}
.why .year-counter_number
{
font-size: 31px;
}
.year-counter.why.counter2
{
left: -71px;
top: 6em;
}
.why-img-c
{
float: right;
position: relative;
top: -2em;
right: -5em;
}
.ani-f
{
z-index: 9;
position: relative;
}
.w-90
{
max-width: 85% !important;
}
span i
{
font-size: 20px;
border-radius: 50%;
border: 1px dashed #f44556;
padding: 9px;
color: #f44556;
}
.checklist h6
{
color:#0c2e8a;
font-weight: 600;
}
.checklist span
{
webkit-transition: .5s all ease;
-moz-transition: .5s all ease;
transition: .5s all ease;
cursor: pointer;
}
.checklist span:hover
{
margin-left: 5px;
}
/* plans */
.pricing-card{
flex: 1;
border-radius: 10px; 
background-color: #fff;
margin: 20px 6px;
text-align: center;
cursor: pointer;
overflow: hidden;
color: #2d2d2d;
transition: .3s linear;
}
.pricing-card-header{
background-color: #0c2e8a;
display: inline-block;
color: #fff;
padding: 12px 30px;
font-family: 'Raleway';
border-radius: 0 0 20px 20px;
font-size: 16px;
text-transform: uppercase;
font-weight: 600;
transition: .4s linear;
box-shadow: rgb(0 0 0 / 9%) 0px 2px 1px, rgb(0 0 0 / 9%) 0px 4px 2px, rgb(0 0 0 / 9%) 0px 8px 4px, rgb(0 0 0 / 9%) 0px 16px 8px, rgb(0 0 0 / 9%) 0px 32px 16px;
}
.pricing-card:hover{
box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
}
.price{
font-size: 16px;
color: #f44556;
margin: 20px 0 30px 0;
transition: .2s linear;
font-weight: 600;
border-radius: 10px;
box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}
.price sup, .price span{
font-size: 25px;
font-weight: 700;
font-family: 'Poppins', sans-serif!important; 
}
.pricing-card li{
font-size: 13px;
padding: 6px 0;
font-weight: 500;
list-style: none;
font-family: 'Poppins', sans-serif!important; 
}
.packages .custom-btn{
margin-bottom: 20px;
margin-top: 20px; 
}
.packages a:hover{
color: #fff;
}
@media screen and (max-width:1100px){
.pricing-card{
flex: 50%;
}
}
.package{
min-height: 45px;
}
.package-title{
font-size: 16px !important;
font-weight: 600 !important;
color: #f44556;
}
.paperback-wd{
min-width: 265px;
}
.p-20{
padding: 21px 0px !important; 
}
.ebook-wd{
min-width: 320px;
}
.react-tabs__tab--selected {
background: #f44556 !important;
color: #fff !important;
border-radius: 20px !important;
padding: 8px 30px !important;
border-color: #f44556 !important;
}
.react-tabs__tab { 
list-style: none !important;
position: unset !important; 
padding: 8px 38px !important;
font-family: 'Poppins', sans-serif!important; 
font-weight: 500;
}
.react-tabs__tab-list {
padding: 6px 8px !important;
border:1px dashed #0c2e8a !important;
border-radius: 50px !important;
}
.mt-n2
{
margin-top: -2rem !important;
}
.pricing-card ul li .pi
{
border-radius: 50%;
border: 1px dashed #28a745;
padding: 5px;
font-size: 24px;
float: left;
color: #28a745;
}
.pricing-card ul li .pi-n
{
border-radius: 50%;
border: 1px dashed #f44556;
padding: 5px;
font-size: 24px;
float: left;
color:#f44556;
}
/* testimonial */
.testi-s
{
background-image: url(../img/testimonial/testi_bg_2.png);
background-position: center;
background-size: cover;
background-repeat: no-repeat;
}
.testi-box {
position: relative;    
display: flex;
background-color: #fff;
box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
border-radius: 10px;
gap: 20px;
padding: 16px 12px;
}
.testi-box_img {
min-width: 130px;
text-align: center;
}
.testi-box_img>img {
border-radius: 10px;
width: 100%
}
.testi-box_quote {
height: 56px;
width: 56px;
line-height: 52px;
background-color: #0060FF;
border-radius: 50%;
text-align: center;
position: relative;
z-index: 2;
margin: -28px auto 0 auto
}
.testi-box_quote img {
position: relative;
z-index: 1
}
.testi-box_quote:after,
.testi-box_quote:before {
content: "";
position: absolute;
inset: 0;
background-color: #0060FF;
z-index: -1;
border-radius: 50%;
-webkit-transition: all ease 0.4s;
transition: all ease 0.4s
}
.testi-box_quote:after {
-webkit-animation-delay: 2s;
animation-delay: 2s
}
.testi-box .box-title {
margin-bottom: 4px;
font-family: 'Poppins', sans-serif!important; 
}
.testi-box_text { 
padding-bottom: 7px;
font-size: 13px;
text-align: justify;
border-bottom: 1px dashed #f44556;
color: #737887;
margin-bottom: 5px;
}
.testi-box_quote:after,
.testi-box_quote:before,
.testi-grid_quote:after,
.testi-grid_quote:before
{
-webkit-animation-duration: 5s;
animation-duration: 5s;
-webkit-animation-timing-function: ease-in-out;
animation-timing-function: ease-in-out;
-webkit-animation-iteration-count: infinite;
animation-iteration-count: infinite;
-webkit-animation-name: ripple;
animation-name: ripple
}
@keyframes ripple {
0% {
-webkit-transform: scale(1);
transform: scale(1);
opacity: 0
}
30% {
opacity: 0.4
}
100% {
-webkit-transform: scale(1.8);
transform: scale(1.8);
opacity: 0
}
}
.testi-box_review .star
{
color: #f9d71c;
}
.shape-mockup1 {
position: relative;
z-index: -1;
top: -3em;
}
.shape-mockup2
{
position: relative;
z-index: -1;
top: -7em;
margin-bottom: -7em;
}
.jump {
-webkit-animation: jumpAni 7s linear infinite;
animation: jumpAni 7s linear infinite;
}
.moving {
-webkit-animation: moving 8s linear infinite;
animation: moving 8s linear infinite;
}
@keyframes moving {
0% {
-webkit-transform: translateX(0);
transform: translateX(0)
}
50% {
-webkit-transform: translateX(-50px);
transform: translateX(-50px)
}
100% {
-webkit-transform: translateX(0);
transform: translateX(0)
}
}
.jump {
-webkit-animation: jumpAni 7s linear infinite;
animation: jumpAni 7s linear infinite
}
@keyframes jumpAni {
0% {
-webkit-transform: translateY(0);
transform: translateY(0)
}
40% {
-webkit-transform: translateY(-30px);
transform: translateY(-30px)
}
100% {
-webkit-transform: translateY(0);
transform: translateY(0)
}
}
.testi-s .carousel-inner
{
padding: 2.5em 1em;
}
.testi-s .carousel-indicators button
{
display: none;
}
.testi-s .carousel-control-prev  {
display: block!important;
top: -3em;
left: 68em;
width: 0%;
opacity: 1;
webkit-transition: .5s all ease;
-moz-transition: .5s all ease;
transition: .5s all ease;
}
.testi-s .carousel-control-next {
display: block!important;
top: -3em;   
opacity: 1;
webkit-transition: .5s all ease;
-moz-transition: .5s all ease;
transition: .5s all ease;
width: 0%;
right: 1em;
}
.testi-s .carousel-control-next-icon, .testi-s .carousel-control-prev-icon {  
position: relative;
float: right;
margin-right: 2em;
}
.testi-s .carousel-control-prev-icon {
background-image: url(../img/testimonial/left.png) !important;
}
.testi-s .carousel-control-next-icon {
background-image: url(../img/testimonial/right.png) !important;
}
.testi-s .carousel-control-prev:hover {
margin-left: -10px !important;
}
.testi-s .carousel-control-next:hover {
margin-right: -10px !important;
}
.dist .col-md-3
{
width: 245px;
height: 100px;
background: #fff;
border-radius: 10px;
margin: 10px;
float: left;
padding:20px;
box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);    
display: flex;
align-items: center;
justify-content: center;
}
.dist .col-md-3 img
{
max-width: 180px;
}
.distribution-platforms .carousel-indicators button
{
width: 14px!important;
height: 14px!important;
border: 3px solid #f4f9ff!important;
border-radius: 50%;
box-shadow: 1px 1px 2px rgb(0 0 0 / 90%);
}
.distribution-platforms .carousel-indicators button.active
{
background-color: #0060FF;
}
.navbar-nav .active
{
color: #f44556 !important;
font-weight: 700;
}
/*  */
footer {   
background-image: url(../img/footer_bg_1.jpg);
background-size: cover;
background-position: center;
background-repeat: no-repeat;
}
.footer {   
flex-wrap: wrap;   
padding: 63px 8vw;
color: #e2e0e5;
}
.footer .h5 {
font-size: 18px;  
color: #f44556;
margin-bottom: 12px;
font-weight: 600;
font-family: 'Poppins';
}
.text-justify
{
text-align: justify;
}
.footer .brand .h5 {
display: flex;
text-transform: uppercase;
gap: 16px;
}
.foot-link
{
list-style-type: none;
}
.foot-link li
{
padding: 7px 0px;
}
.foot-logo
{
max-width: 298px!important;
margin-top: -21px;
}
.h5
{
position: relative;
}
.h5::before
{
content: '';
height: 2px;
width: 100px;
background-color: #0c2e8a;
position: absolute;
left: 0;
bottom: -7px;
}
.mx-15
{
max-width: 15% !important;
}
.copyright a
{
font-size: 14px;
}
.h5::after
{
content: '';
height: 10px;
width: 10px;
background-color:#fff;
position: absolute;
bottom: -11px;
left: 96px;
border-radius: 99px;
border: 2px solid #0c2e8a;
}
.social {
display: flex;
flex-direction: row;
gap: 16px;
}
.social .social-icon {
display: flex;
align-items: center;
justify-content: center;
width: 36px;
height: 36px;
background-color:#fff;
color: #f44556;
border-radius: 20% 20% 20% 20%;
}
.social a {
transition: all 0.3s ease;
}
.social a:hover {
box-shadow: 0px 0px 20px 1px #726193;
transform: scale(1.1);
}
.copyright {
padding: 24px 8vw 5px;
background-color: #0c2e8a;
}
.copyright a {
color: #fff;
margin: 0 0.5vw 0 0.5vw;
}
.copyright a:last-child {
margin-right: 0;
}
.copyright a::before {
background-color: #0a001a !important;
}
.copyright .col-md-3 p
{
color:#f44556 ;
}
.text-red-c
{
color:#f44556 ;
}
.copyright a:hover
{
color:#f44556 ;
} 
footer a {
position: relative;
font-family: 'Poppins', sans-serif;
color: #000;
font-size: 14px;
}
.brand
{
width: 28.333333% !important;
}
.add
{
width: 23% !important;
}
footer a:not(.social-icon)::before {
background-color:#0c2e8a;
content: ' ';
position: absolute;
width: 100%;
height: 1px;
border-radius: 4px;
bottom: -2px;
left: 0;
transform-origin: right;
transform: scaleX(0);
transition: transform 0.3s ease-in-out;
}
footer a:hover::before {
transform-origin: left;
transform: scaleX(1);
}
.breadcumb-menu {
display: flex;
justify-content: center;   
list-style-type: none;
font-family: 'Raleway', sans-serif;
}
.breadcumb-menu li {
font-weight: 600;
font-size: 20px;
color: #f44556;
padding-left: 4px;
font-style: italic;
}
.breadcumb-menu li a
{
font-weight: 600;
font-size: 18px;
color: #fff;
font-style: italic;
}
.breadcumb-title {
color: #fff;
font-family: 'Poppins';
font-weight: 600;
}
.breadcumb-wrapper {
padding: 160px 0;   
text-align: center;
}
.about-img
{
    background: linear-gradient(rgba(0, 0, 0, 0.60), rgba(0, 0, 0, 0.30)),url(../img/breadcrumb/about.webp); 
    background-position:center;
    background-repeat: no-repeat;
    background-size: cover;
}
.ebook-img
{
    background: linear-gradient(rgba(0, 0, 0, 0.60), rgba(0, 0, 0, 0.60)),url(../img/breadcrumb/ebook.jpg); 
    background-position:center;
    background-repeat: no-repeat;
    background-size: cover;
}
/* team */

.team { 
    padding: 5em 0;
}

h3.title {
    color: #faad81;
    font-size: 50px;
    text-align: center;
    margin-bottom: 1em;
    font-weight: 600;
    font-style: italic;
}
.team-grid {
    text-align: center;
    cursor: pointer;
}

/*-- Team --*/


.team-grid h4 {
    font-size: 23px;
    color: #f44556;
    font-weight: 500;
    margin: 11px 0 0px;
}

.team-grid p {
    color: #777;
    font-size: 1em;
    font-family: 'Raleway', sans-serif;
}



.stack img {
    width: 100%;
    height: auto;
    vertical-align: bottom;
    border: 5px solid #fff;
    border-radius: 3px;  
    
    -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.4);
   
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.4);
    
}
.stack:last-of-type {
    margin-right: 0;
}

/* Third stack example (One stack element rotated in the opposite direction) */

.stack.twisted:before {
    -webkit-transform: rotate(4deg);
    -moz-transform: rotate(4deg);
    transform: rotate(4deg);
    -moz-transform: rotate(4deg);
    -o-transform: rotate(4deg);
}

.stack.twisted:after {
    -webkit-transform: rotate(-4deg);   
    transform: rotate(-4deg);
}

/* Reset all rotations on hover */

.stack:hover:before,
.stack:hover:after,
.team-grid:hover .stack:before,
.team-grid:hover .stack:after {
    -webkit-transform: rotate(0deg);   
    transform: rotate(0deg);
}

/* Stacks creted by the use of generated content */

.stack:before,
.stack:after {
    content: "";
    border-radius: 3px;   
    width: 100%;
    height: 100%;
    position: absolute;
    border: 10px solid #fff;
    left: 0;
  
   
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.4);   
    -webkit-transition: 0.3s all ease-out;    
    transition: 0.3s all ease-out;
  
}

.stack:before {
    top: 4px;
    z-index: -10;
}

/* 1st element in stack (behind image) */

.stack:after {
    top: 8px;
    z-index: -20;
}

/* 2nd element in stack (behind image) */

.stack {
    float: none;
    width: 92%;
    margin: 3% 0% 8% 4%;
    position: relative;
    z-index: 1;
}


  .mx-90
  {
    max-width: 90px!important;
  }
.max-125
{
    max-width: 125px !important;
}
  .sub-main
  {
    color: #898989;
    font-size: 16px;    
    font-weight: 400;
  }
.plans-shadow
{
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
}
.plans-shadow:hover
{
    background-color: #f4f9ff;
}
 /* hgfgju */
 .effect4 {
	
	position: relative;
	cursor: pointer;
}

.effect4 span {	
  color: #fff; 
  left: 0;
  text-align: justify;
  font-size: 13px;
  height: 0px;
  position: absolute;
  transition: 0.5s ease all;
	visibility: hidden;
	opacity: 0;
  top:0;
}	
.effect4:hover span {
    background: rgba(0,0,0,0.6);
  visibility: visible;
  height: 100%;
  padding:10px; 
  opacity: 1;
}